import { useContext, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import { useUserContext } from '../../context/UserContext'
import { exgolandURL } from '../../utils/constants/URL'
import { shareAppText } from '../../utils/constants/shareModalItems'
import { WalletConnectContext } from '../../providers/web3'
import ShareModal from '../Navigation/shareModal/ShareModal'
import shareSVG from '../../assets/images/share.svg'
import shareMobileSVG from '../../assets/images/shareBtnMobileIcon.svg'
import { formatText } from '../../helpers/textFormatter'
import { WalletPopup } from '../walletPopup/walletPopup'
import CopyButton from '../copy-btn'
import styles from './top.module.scss'
import { MagicWalletPopup } from '../magicWalletPopup/magicWalletPopup'

export default function Top() {
  const [connectWalletHover, setConnectWalletHover] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const { isModalOpen, openModal, closeModal } = useContext(WalletConnectContext)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const wallet = useWallet()
  const { isLoggedin, user } = useUserContext()

  const handleShare = () => {
    isMobile ? mobileShare() : setOpen(true)
  }

  const mobileShare = async () => {
    try {
      await navigator.share({
        url: exgolandURL,
        text: shareAppText,
        title: 'Exgoland',
      })
    } catch (error) {
      console.error('Error sharing:', error)
    }
  }

  const showCopyBtn = () => {
    return (wallet?.publicKey || (isLoggedin && user?.publicAddress)) 
  }

  const showPublicAddress = () => {
    if (wallet?.publicKey) {
      return wallet?.publicKey?.toBase58()
    } else if(user?.publicAddress) {
      return user?.publicAddress
    } else {
      return ''
    }
  }

  const showCopyBtnText = () => {
    if (wallet.connected) {
      return formatText(wallet?.publicKey?.toBase58())
    } else if(user?.publicAddress) {
      return formatText(user?.publicAddress)
    } else {
      return 'Connect wallet'
    }
  }

  return (
    <>
      <MagicWalletPopup />
      <WalletPopup />
      <Box className={styles.buttonsSection}>
        <Box className={styles.relative}>
          <Button
            onClick={handleShare}
            className={isMobile ? styles.shareButtonMobile : styles.shareButton}>
            share
            <img src={isMobile ? shareMobileSVG : shareSVG} alt='share' />
          </Button>
          {open && <ShareModal handleClose={() => setOpen(false)} />}
        </Box>
        <Box className={styles.relative}>
          <Button
            onMouseEnter={() => {
              setConnectWalletHover(true)
            }}
            onMouseLeave={() => {
              setConnectWalletHover(false)
            }}
            className={`${wallet.connected ? styles.openedModal : ''} ${
              wallet.connected ? styles.walletButtonConnected : styles.connectWalletButton
            }`}
            // disabled={true}
            onClick={() => openModal()}
          >
            {showCopyBtnText()}
          </Button>
          {showCopyBtn() && (
            <span className={styles.copy}>
              <CopyButton isWhite={true} text={showPublicAddress()} />
            </span>
          )}
          {isModalOpen && <Box className={styles.curtain} onClick={closeModal} />}
        </Box>
      </Box>
    </>
  )
}
