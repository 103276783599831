import React, { createContext, useEffect, useMemo, useState } from 'react'
import { GlowWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { Cluster, clusterApiUrl } from '@solana/web3.js'
interface WalletConnectContextType {
  isModalOpen: boolean
  closeModal: () => void
  openModal: (centered?: boolean, isCrossmintVisible?: boolean) => void
  isCentered: boolean | undefined
  showCrossMint: boolean | undefined,
  magicModalOpen: boolean,
  openMagicModal: () => void,
  closeMagicModal: () => void,
}

export const WalletConnectContext = createContext<WalletConnectContextType>({
  isModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  isCentered: false,
  showCrossMint: false,
  magicModalOpen: false,
  openMagicModal: () => {},
  closeMagicModal: () => {}
})

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const [autoConnect, setAutoConnect] = useState<boolean>(false)

  const network = process.env.REACT_APP_SOLANA_NETWORK as Cluster

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [magicModalOpen, setMagicModalOpen] = useState(false)
  const [isCentered, setIsCentered] = useState<boolean | undefined>(false)
  const [showCrossMint, setShowCrossMint] = useState<boolean | undefined>(false)

  const endpoint = useMemo(() => (process.env.REACT_APP_RPC_URL || clusterApiUrl(network)), [network])

  const wallets = useMemo(() => [new PhantomWalletAdapter(), new GlowWalletAdapter()], [network])

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) setAutoConnect(true)
  }, [])

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={autoConnect}>
        <WalletConnectContext.Provider
          value={{
            isModalOpen,
            closeModal: () => {
              setIsModalOpen(false)
            },
            openModal: (centered = false, isCrossmintVisible = false) => {
              setIsCentered(centered)
              setShowCrossMint(isCrossmintVisible)
              setIsModalOpen(true)
            },
            isCentered,
            showCrossMint,
            magicModalOpen,
            openMagicModal: () => setMagicModalOpen(true),
            closeMagicModal: () => setMagicModalOpen(false)
          }}>
          {children}
        </WalletConnectContext.Provider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
